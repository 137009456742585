<template>
  <div class='col-12 col-lg-6 g c'>
    <br><br><br><br><br><br>
    <div class="card card-body" style="border-radius: 50px">
        <h1 class="text-center">
            تواصل عبر الواتساب على:
            <br><br>
            <strong class="text-success"><i class="fa fa-whatsapp"></i> 966580200670</strong>
            <br><br>
            <small>
              هل لديك اقتراح تريد ارساله لنا؟ <a href="javascirpt:;" @click="$router.push('/feedback')"><i class="fa fa-edit"></i> شاركنا برأيك واقتراحك</a>
            </small>
        </h1>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>